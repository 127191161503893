<template>
  <v-container fluid>
    <base-material-card
      icon="fas fa-file-invoice"
      title="Reporte Sell In / Sell Out"
      class="px-5 py-3"
    >
      <v-row class="mt-3" dense>
        <v-col cols="12" sm="4" lg="3">
          <v-menu
            v-model="menuFechaInicio"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_inicial"
                label="Fecha inicial"
                v-on="on"
                readonly
                dense
            ></v-text-field>
            </template>
            <v-date-picker
              elevation-12
              v-model="pagination.fecha_inicial"
              :max="pagination.fecha_final"
              @input="
                menuFechaInicio = false;
                load();
              "
              locale="es-MX"
              class="ma-0"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <v-menu
            v-model="menuFechaFin"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_final"
                label="Fecha fin"
                v-on="on"
                readonly
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.fecha_final"
              :min="pagination.fecha_inicial"
              @input="
                menuFechaFin = false;
                load();
              "
              locale="es-MX"
              class="ma-0"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/vendedores/all"
            text="razon_social"
            label="Vendedor"
            :valor="pagination.vendedor_id"
            first-text="Todos"
            multiple
            dense
            @change="
              pagination.vendedor_id = $event;
              load();
            "
          ></custom-select-2>
        </v-col>
        
        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/rutas"
            text="nombre"
            label="Ruta"
            :valor="pagination.ruta_id"
            first-text="Todos"
            multiple
            dense
            @change="
              pagination.ruta_id = $event;
              load();
            "
          ></custom-select-2>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/productos"
            text="nombre"
            label="Productos"
            :valor="pagination.producto_id"
            :first-select="true"
            :params="{all: true}"
            first-text="Todos"
            paginate
            multiple
            dense
            @change="
              pagination.producto_id = $event;
              obtenerSucursales($event);
              load();
            "
          ></custom-select-2>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/clientes"
            text="razon_social"
            label="Clientes"
            :valor="pagination.cliente_id"
            :first-select="true"
            :params="{per_page: 15}"
            first-text="Todos"
            paginate
            multiple
            dense
            @change="
              pagination.cliente_id = $event;
              obtenerSucursales($event);
              load();
            "
          ></custom-select-2>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <v-autocomplete
            label="Sucursales"
            :items="sucursales"
            v-model="pagination.sucursal_id"
            item-text="nombre"
            item-value="id"
            no-data-text="No se encontraron sucursales."
            multiple
            dense
            :loading="loading.sucursal"
            :disabled="0 === pagination.cliente_id.length"
            @change="
              pagination.sucursal_id = $event;
              load();
            "
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/metodos-pago"
            text="descripcion"
            label="Método Pago"
            :valor="pagination.metodo_pago_id"
            :first-select="true"
            :params="{all: true}"
            first-text="Todos"
            paginate
            multiple
            dense
            @change="
              pagination.metodo_pago_id = $event;
              load();
            "
          ></custom-select-2>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            :loading="loading.pdf"
            @click="downloadPdf()"
            :disabled="0 === datos.length"
          >Descargar PDF</v-btn>
          <v-btn
            color="success"
            @click="downloadExcel()"
            :disabled="0 === datos.length"
          >Descargar Excel</v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="datos"
            width="100%"
            :loading="loading.table"
            hide-default-footer
          >
            <template v-slot:item.total_sell_in="{ item }">
              <a @click="mostrarModalDetalle(item)">{{ formatoMoneda(item.total_sell_in) }}</a>
            </template>

            <template v-slot:item.total_sell_out="{ item }">
              <a @click="mostrarModalDetalle(item)">{{  formatoMoneda(item.total_sell_out) }}</a>
            </template>

            <template v-slot:body.append>
              <tr>
                <td colspan="5"></td>
                <td
                  v-if="3 !== pagination.tipo_reporte_id"
                  class="font-weight-bold text-center"
                >{{ formatoMoneda(totalSellIn) }}</td>
                <td
                  v-if="2 !== pagination.tipo_reporte_id"
                  class="font-weight-bold text-center"
                >{{ formatoMoneda(totalSellOut) }}</td>
              </tr>
            </template>

            <template v-slot:no-data>
              <span>No hay datos disponibles</span>                
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination 
            v-model="pagination.page"
            class="my-4"
            :length="pagination.total_page"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>

    <detalle-sell-in-sell-out
      :mostrar="modalDetalle"
      :datos="modalDatos"
      :productos="productos"
      @ocultar="modalDetalle = false"
    ></detalle-sell-in-sell-out>
  </v-container>
</template>

<script>
import CustomSelect2 from '@views/dashboard/component/v2/CustomSelect.vue';
import DetalleSellInSellOut from '@views/dashboard/component/v2/DetalleSellInSellOut.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'SellInSellOutDetallado',
  components: {
    CustomSelect2,
    DetalleSellInSellOut
  },
  data: () => ({
    pagination: {
      fecha_inicial: moment().format('YYYY-MM-DD'),
      fecha_final: moment().format('YYYY-MM-DD'),
      vendedor_id: [],
      ruta_id: [],
      producto_id: [],
      cliente_id: [],
      sucursal_id: [],
      metodo_pago_id: [''],
      page: 1,
      last_page: 1,
      total: 0,
      total_page: 0,
    },
    menuFechaInicio: false,
    menuFechaFin: false,
    headers: [
      {text: 'Fecha', value: 'fecha', sortable: true},
      {text: 'Ruta', value: 'ruta.nombre', sortable: true},
      {text: 'Vendedor', value: 'vendedor.razon_social', sortable: true},
      {text: 'Cliente', value: 'sucursal.cliente.razon_social', sortable: true},
      {text: 'Sucursal', value: 'sucursal.nombre', sortable: true},
      {text: 'Sell In', value: 'total_sell_in', sortable: true},
      {text: 'Sell Out', value: 'total_sell_out', sortable: true},
    ],
    datos: [],
    sucursales: [],
    modalDetalle: false,
    modalDatos: {},
    productos: [],
    loading: {
      table: false,
      pdf: false,
      excel: false,
      sucursal: false
    },
  }),
  mounted() {
    this.load();
  },
  computed: {
    totalSellIn: function () {
      if (0 === this.datos.length) {
        return 0;
      }

      return this.datos.reduce((total, item) => {
        return total + item.total_sell_in;
      }, 0);
    },

    totalSellOut: function () {
      if (0 === this.datos.length) {
        return 0; 
      }

      return this.datos.reduce((total, item) => {
        return total + item.total_sell_out;
      }, 0);
    },
  },
  methods: {
    load: _.debounce(function () {
        this.loading.table = true;

        this.$http
          .get('/reportes/ventas/sellin-sellout', {
            params: this.pagination,
          })
          .then((response) => {
            this.datos = this.formatArray(response.data.data);
            this.pagination.last_page = response.data.last_page;
            this.pagination.page = response.data.current_page;
            this.pagination.total = response.data.total;
            this.pagination.total_page = this.calcularNumeroPaginas(response.data.total, response.data.per_page);
          })
          .finally(() => {
            this.loading.table = false;
          });
    }, 500),

    formatArray(items) {
      let newArray = [], count = 0;

      for(let i in items) {
        newArray[count++] = items[i];
      };

      return newArray;
    },

    calcularNumeroPaginas(items, perPage) {
      if (items < perPage) {
        return 1;
      }

      let pages = items / perPage;

      if (items%perPage > 0) {
        pages += 1;
      }

      return parseInt(pages);
    },

    mostrarModalDetalle(datos) {
      this.modalDatos = datos;

      this.$http
          .get(`/reportes/ventas/sellin-sellout/detalle/${datos.sucursal_id}`, {
            params: {
              fecha: datos.fecha,
              ruta_visita_id: datos.id,
              vendedor_id: datos.vendedor_id
            },
          })
          .then((response) => {
            this.productos = response.data.data;
          })
          .finally(() => {
            this.loading.table = false;
          });

      this.modalDetalle = true;
    },

    downloadPdf() {
      this.loading.pdf = true;

      this.$http({
        url: `/reportes/ventas/sellin-sellout/pdf`,
        method: "GET",
        responseType: "blob",
        params: this.pagination,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteSellInSellOut.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loading.pdf = false;
      });
    },

    downloadExcel() {
      this.loading.excel = true;

      this.$http({
        url: `/reportes/ventas/sellin-sellout/excel`,
        method: "GET",
        responseType: "blob",
        params: this.pagination,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteSellInSellOut.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loading.excel = false;
      });
    },

    obtenerSucursales(clienteIds) {
      
      if (0 === clienteIds.length) {
        return;
      }
      this.loading.sucursal = true;
   
      this.$http.get('/sucursales/all', {
        params: {
          all: false,
          cliente_id: clienteIds,
          multiple: true,
        }
      }).then(response => {
        this.sucursales = response.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading.sucursal = false;
        }, 600);
      });
    },
  },
};
</script>
