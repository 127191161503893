<template>
  <div class="text-center">
    <v-dialog v-model="mostrar" width="1100" @keydown.esc="$emit('ocultar')" @click:outside="$emit('ocultar')">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Detalle de visita al cliente
          </span>
        </v-card-title>

        <v-card-text>
          <v-col cols="12" class="mb-5">
            <v-btn
              color="primary"
              :loading="loading.pdf"
              @click="downloadPdf()"
            >Descargar PDF</v-btn>
            <v-btn
              color="success"
              :loading="loading.excel"
              @click="downloadExcel()"
            >Descargar Excel</v-btn>
          </v-col>
          <v-simple-table class="mb-5">
            <template v-slot:default>
              <thead>
              <tr>
                  <th class="text-left">Ruta</th>
                  <th class="text-left">Vendedor</th>
                  <th class="text-left">Sucursal</th>
                  <th class="text-left">Cliente</th>
                  <th class="text-left">Fecha</th>
              </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>{{ datos.ruta.nombre }}</td>
                    <td>{{ datos.vendedor.razon_social }}</td>
                    <td>{{ datos.sucursal.nombre }}</td>
                    <td>{{ datos.sucursal.cliente.razon_social }}</td>
                    <td>{{ datos.fecha }}</td>
                  </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-data-table
            :headers="encabezados"
            :items="productos"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.visita_anterior="{ item }">
              <!-- {{ item.producto.nombre }} - {{ item.conteo.conteo_productos.length}} -->
              {{ 0 === item.conteo.conteo_productos.length ? '0' : item.conteo.conteo_productos[0].cantidad_anterior }}
            </template>

            <template v-slot:item.fisico_actual="{ item }">
              {{ 0 === item.conteo.conteo_productos.length ? '0' : item.conteo.conteo_productos[0].cantidad }}
            </template>

            <template v-slot:item.sell_out_venta_pesos="{ item }">
              {{ formatoMoneda(item.sell_out_venta_pesos) }}
            </template>

            <template v-slot:item.sell_in_venta_pesos="{ item }">
              {{ formatoMoneda(item.sell_in_venta_pesos) }}
            </template>

            <template v-slot:item.devoluciones_importe="{ item }">
              {{ formatoMoneda(item.devoluciones_importe) }}
            </template>

            <template v-slot:item.inventario_final_venta_actual_pesos="{ item }">
              {{ formatoMoneda(item.inventario_final_venta_actual_pesos) }}
            </template>
            
            <template v-slot:no-data>No existen detalles de almacén</template>
            <template v-slot:body.append>
                <tr>
                  <td class="font-weight-bold text-right">Total en pesos</td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalVisitaAnterior) }}</td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalFisicoActual) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalSellOutPesos) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalDevoluciones) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalSellInPesos) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-right">{{ formatoMoneda(totalInventarioVentaActual) }}</td>
                </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('ocultar', true)">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  export default {
    name: 'DetalleSellInSellOut',
    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      productos: {
        type: Array,
        required: true,
      },
      datos: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        encabezados: [
          { text: 'Producto', value: 'producto.nombre', sortable: true },
          { text: 'Visita Anterior', value: 'visita_anterior', sortable: true, align: 'center' },
          { text: 'Físico Actual', value: 'fisico_actual', sortable: true, align: 'center' },
          { text: 'Sell Out Venta en Piezas', value: 'sell_out_venta_piezas', sortable: true, align: 'center' },
          { text: 'Sell Out Venta en $', value: 'sell_out_venta_pesos', sortable: true, align: 'right' },
          { text: 'Devolución en Piezas', value: 'devoluciones_cantidad', sortable: true, align: 'center' },
          { text: 'Devolución en $', value: 'devoluciones_importe', sortable: true, sortable: true, align: 'right' },
          { text: 'Sell In Venta en piezas', value: 'sell_in_venta_piezas', sortable: true, align: 'center' },
          { text: 'Sell In Venta en $', value: 'sell_in_venta_pesos', sortable: true, sortable: true, align: 'right' },
          { text: 'I. Final V. Actual', value: 'inventario_final_venta_actual', sortable: true, sortable: true, align: 'center' },
          { text: 'I. Final V. Actual', value: 'inventario_final_venta_actual_pesos', sortable: true, sortable: true, align: 'right' },
        ],
        loading: {
          pdf: false,
          excel: false,
        },
      };
    },
    computed: {
      totalSellOutPesos: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
            return total + item.sell_out_venta_pesos;
          }, 0);
        }

        return 0;
      },

      totalSellInPesos: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
            return total + item.sell_in_venta_pesos;
          }, 0);
        }

        return 0;
      },

      totalDevoluciones: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
              return total + item.devoluciones_importe;
          }, 0);
        }

        return 0;
      },

      totalInventarioVentaActual: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
            return total + item.inventario_final_venta_actual_pesos;
          }, 0);
        }

        return 0;
      },

      totalVisitaAnterior: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
            return total + item.visita_anterior_peso;
          }, 0);
        }

        return 0;
      },

      totalFisicoActual: function () {
        if (this.productos.length) {
          return this.productos.reduce((total, item) => {
            return total + item.fisico_actual_peso;
          }, 0);
        }

        return 0;
      },
    },
    methods: {
      downloadPdf() {
        this.loading.pdf = true;

        this.$http({
          url: `/reportes/ventas/sellin-sellout/detalle/${this.datos.sucursal_id}/pdf`,
          method: "GET",
          responseType: "blob",
          params: {
            fecha: this.datos.fecha,
            ruta_visita_id: this.datos.id,
            vendedor_id: this.datos.vendedor_id,
            ruta_nombre: this.datos.ruta.nombre,
            vendedor: this.datos.vendedor.razon_social,
            sucursal: this.datos.sucursal.cliente.razon_social,
            cliente: this.datos.sucursal.nombre,
          },
        }).then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `ReporteDetalleSellInSellOut.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).finally(() => {
          this.loading.pdf = false;
        });
      },

      downloadExcel() {
        this.loading.excel = true;

        this.$http({
          url: `/reportes/ventas/sellin-sellout/detalle/${this.datos.sucursal_id}/excel`,
          method: "GET",
          responseType: "blob",
          params: {
            fecha: this.datos.fecha,
            ruta_visita_id: this.datos.id,
            vendedor_id: this.datos.vendedor_id,
            ruta_nombre: this.datos.ruta.nombre,
            vendedor: this.datos.vendedor.razon_social,
            sucursal: this.datos.sucursal.cliente.razon_social,
            cliente: this.datos.sucursal.nombre,
          },
        }).then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `ReporteDetalleSellInSellOut.xls`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).finally(() => {
          this.loading.excel = false;
        });
      },
    },
  };
  </script>